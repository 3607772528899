<template>
	<div class="custom-page-header-count pt-0 px-0">
		<v-layout>
			<template>
				<template v-for="(data, index) in statusList">
					<v-flex
						:key="index"
						class="py-0"
						:class="{
							'mr-1': index == 0,
							'ml-1': !statusList[index + 1],
							'mx-1': index != 0 && statusList[index + 1],
						}"
					>
						<!-- quotation -->
						<div
							:class="'card m-0 top_card card-custom gutter-b border-' + data.color"
							v-if="data.type == 'quotation'"
						>
							<div class="card-body p-0" @click="getFilteredData(data.value)">
								<div class="mx-2 d-flex justify-space-between py-3">
									<div >
										<h5 class="mb-2 w-100 text-dark-55 font-weight-bold m-0 text-right" :class="`${data.color}--text`">
											{{ data.text }}
										</h5>
										<inline-svg
											style="width: 40px; height: 40px"
											fill="#baa9a9"
											:src="$assetURL('media/custom-svg/' + data.type + '.svg')"
										/>
										
									</div>
									<div class="d-flex flex-column justify-space-between">
										
										
										<!-- v-if="data.text != 'All Quotation'" -->
										<v-avatar
											v-if="data.text != 'All Quotation'"
											style="margin-left: auto"
											class="text-right text-white"
											size="35"
											:color="data.color"
											:text-color="data.textcolor"
											>{{ data.quotation_status_count }}</v-avatar
										>
										<v-avatar
											v-if="data.text == 'All Quotation'"
											style="margin-left: auto"
											class="text-right text-white"
											size="35"
											:color="data.color"
											:text-color="data.textcolor"
											>{{ statusCount }}</v-avatar
										>
										<div class = "mt-1">
											<v-chip
												label
												:color="`${data.color} white--text text--darken-4 text-capitilize`"
												class=""
											>
												<ShowPrice 
													tooltip
													tooltip-value="Quotation Value" 
													:object="data" 
													object-key="quotation_amount" 
													label="Quotation Value"
												> </ShowPrice>
											</v-chip>
										</div>
									</div>
								</div>
							</div>
							
						</div>
						
						<!-- Purchase Orders -->

						<div
							:class="'card m-0 top_card card-custom gutter-b border-' + data.color"
							v-if="data.type == 'purchase-order'"
						>
							<div class="card-body p-0" @click="getFilteredData(data.value)">
								<div class="mx-2 d-flex justify-space-between py-3">
									<div class = "">
										<h5 class="mb-2 w-100 text-dark-55 font-weight-bold m-0" :class="`${data.color}--text`">
											{{ data.text }}
										</h5>
										<inline-svg
											style="width: 40px; height: 40px"
											fill="#baa9a9"
											:src="$assetURL('media/custom-svg/' + data.type + '.svg')"
										/>

										
									</div>
									<div class="d-flex flex-column justify-space-between">
										
										<!-- v-if="data.text != 'All Quotation'" -->
										<v-avatar
											v-if="data.text != 'All Purchase Orders'"
											style="margin-left: auto"
											class="text-right text-white"
											size="35"
											:color="data.color"
											:text-color="data.textcolor"
											>{{ data.quotation_status_count }}</v-avatar
										>
										<v-avatar
											v-if="data.text == 'All Purchase Orders'"
											style="margin-left: auto"
											class="text-right text-white"
											size="35"
											:color="data.color"
											:text-color="data.textcolor"
											>{{ statusCount }}</v-avatar
										>
										<div class = "mt-1">
											<v-chip
												label
												:color="`${data.color} white--text text--darken-4 text-capitilize`"
												class=""
											>
												<ShowPrice 
													tooltip
													tooltip-value="Purchase Order Value" 
													:object="data" 
													object-key="po_amount" 
													label="Purchase Order Value"
												> </ShowPrice>
											</v-chip>
										</div>
									</div>
								</div>
							</div>
						</div>

						<!-- Request PO -->
						<div
							:class="'card m-0 top_card card-custom gutter-b border-' + data.color"
							v-if="data.type == 'request-po'"
						>
							<div class="card-body p-0" @click="getFilteredData(data.value)">
								<div class="mx-2 d-flex justify-space-between py-3">
									<div class = "">
										<h5 class="mb-2 w-100 text-dark-55 font-weight-bold m-0" :class="getColor(data.color)">
											{{ data.text }}
										</h5>
										<inline-svg
											style="width: 60px; height: 50px"
											fill="#baa9a9"
											:src="$assetURL('media/custom-svg/' + data.type + '.svg')"
										/>
									</div>
									<div class="d-flex flex-column justify-space-between">
										
										<!-- v-if="data.text != 'All Quotation'" -->
										<v-avatar
											v-if="data.text != 'All Request PO'"
											style="margin-left: auto"
											class="text-right text-white"
											size="35"
											:color="data.color"
											:text-color="data.textcolor"
											>{{ data.quotation_status_count }}</v-avatar
										>
										<v-avatar
											v-if="data.text == 'All Request PO'"
											style="margin-left: auto"
											class="text-right text-white"
											size="35"
											:color="data.color"
											:text-color="data.textcolor"
											>{{ statusCount }}</v-avatar
										>
									</div>
								</div>
							</div>
						</div>
						<!-- Customer -->
						<div
							:class="'card m-0 top_card card-custom gutter-b border-' + data.color"
							v-if="data.type == 'customer'"
						>
							<div class="card-body p-0" @click="getFilteredData(data.value)">
								<div class="mx-2 d-flex justify-space-between py-3">
									<div class = "">
										<h5 class="mb-2 w-100 text-dark-55 font-weight-bold m-0" :class="`${data.color}--text`">
											{{ data.text }}
										</h5>
										<inline-svg
											style="width: 60px; height: 50px"
											fill="#baa9a9"
											:src="$assetURL('media/custom-svg/' + data.type + '.svg')"
										/>
									</div>
									<div class="d-flex flex-column justify-space-between">
										<!-- v-if="data.text != 'All Quotation'" -->
										<v-avatar
											v-if="data.text != 'All Customers'"
											style="margin-left: auto"
											class="text-right text-white"
											size="35"
											:color="data.color"
											:text-color="data.textcolor"
											>{{ data.quotation_status_count }}</v-avatar
										>
										<v-avatar
											v-if="data.text == 'All Customers'"
											style="margin-left: auto"
											class="text-right text-white"
											size="35"
											:color="data.color"
											:text-color="data.textcolor"
											>{{ statusCount }}</v-avatar
										>
									</div>
								</div>
							</div>
						</div>
						<!-- Supliers -->

						<div
							:class="'card m-0 top_card card-custom gutter-b border-' + data.color"
							v-if="data.type == 'vendor'"
						>
							<div class="card-body p-0" @click="getFilteredData(data.value)">
								<div class="mx-2 d-flex justify-space-between py-3">
									<div class = "">
										<h5 class="mb-2 w-100 text-dark-55 font-weight-bold m-0" :class="`${data.color}--text`">
											{{ data.text }}
										</h5>
										<inline-svg
											style="width: 60px; height: 50px"
											fill="#baa9a9"
											:src="$assetURL('media/custom-svg/' + data.type + '.svg')"
										/>
									</div>
									<div class="d-flex flex-column justify-space-between">
										
										<!-- v-if="data.text != 'All Quotation'" -->
										<v-avatar
											v-if="data.text != 'All Suppliers'"
											style="margin-left: auto"
											class="text-right text-white"
											size="35"
											:color="data.color"
											:text-color="data.textcolor"
											>{{ data.quotation_status_count }}</v-avatar
										>
										<v-avatar
											v-if="data.text == 'All Suppliers'"
											style="margin-left: auto"
											class="text-right text-white"
											size="35"
											:color="data.color"
											:text-color="data.textcolor"
											>{{ statusCount }}</v-avatar
										>
									</div>
								</div>
							</div>
						</div>
						<!-- Product -->
						<div
							:class="'card m-0 top_card card-custom gutter-b border-' + data.color"
							v-if="data.type == 'inventory'"
						>
							<div class="card-body p-0" @click="getFilteredData(data.value)">
								<div class="mx-2 d-flex justify-space-between py-3">
									<div class = "">
										<h5 class="mb-2 w-100 text-dark-55 font-weight-bold m-0" :class="getColor(data.color)">
											{{ data.text }}
										</h5>
										<inline-svg
											style="width: 60px; height: 50px"
											fill="#baa9a9"
											:src="$assetURL('media/custom-svg/' + data.type + '.svg')"
										/>
									</div>
									<div class="d-flex flex-column justify-space-between">
										<!-- v-if="data.text != 'All Quotation'" -->
										<v-avatar
											v-if="data.text != 'All Products'"
											style="margin-left: auto"
											class="text-right text-white"
											size="35"
											:color="data.color"
											:text-color="data.textcolor"
											>{{ data.quotation_status_count }}</v-avatar
										>
										<v-avatar
											v-if="data.text == 'All Products'"
											style="margin-left: auto"
											class="text-right text-white"
											size="35"
											:color="data.color"
											:text-color="data.textcolor"
											>{{ statusCount }}</v-avatar
										>
									</div>
								</div>
							</div>
						</div>

						<!-- Equipment -->
						<div
							:class="'card m-0 top_card card-custom gutter-b border-' + data.color"
							v-if="data.type == 'equipment'"
						>
							<div class="card-body p-0" @click="getFilteredData(data.value)">
								<div class="mx-2 d-flex justify-space-between py-3">
									<div class = "">
										<h5 class="mb-2 w-100 text-dark-55 font-weight-bold m-0" :class="getColor(data.color)">
											{{ data.text }}
										</h5>
										<inline-svg
											style="width: 60px; height: 50px"
											fill="#baa9a9"
											:src="$assetURL('media/custom-svg/' + data.type + '.svg')"
										/>
									</div>
									<div class="d-flex flex-column justify-space-between">
										<!-- v-if="data.text != 'All Equipments'" -->
										<v-avatar
											v-if="data.text != 'All Equipment'"
											style="margin-left: auto"
											class="text-right text-white"
											size="35"
											:color="data.color"
											:text-color="data.textcolor"
											>{{ data.quotation_status_count }}</v-avatar
										>
										<v-avatar
											v-if="data.text == 'All Equipment'"
											style="margin-left: auto"
											class="text-right text-white"
											size="35"
											:color="data.color"
											:text-color="data.textcolor"
											>{{ statusCount }}</v-avatar
										>
									</div>
								</div>
							</div>
						</div>
						<!-- services -->
						<div
							:class="'card m-0 top_card card-custom gutter-b border-' + data.color"
							v-if="data.type == 'services'"
						>
							<div class="card-body p-0" @click="getFilteredData(data.value)">
								<div class="mx-2 d-flex justify-space-between py-3">
									<div class = "">
										<h5 class="mb-2 w-100 text-dark-55 font-weight-bold m-0" :class="`${data.color}--text`">
											{{ data.text }}
										</h5>
										<inline-svg
											style="width: 60px; height: 50px"
											fill="#baa9a9"
											:src="$assetURL('media/custom-svg/' + data.type + '.svg')"
										/>
									</div>
									<div class="d-flex flex-column justify-space-between">
										
										<!-- v-if="data.text != 'All Quotation'" -->
										<v-avatar
											v-if="data.text != 'All Services'"
											style="margin-left: auto"
											class="text-right text-white"
											size="35"
											:color="data.color"
											:text-color="data.textcolor"
											>{{ data.quotation_status_count }}</v-avatar
										>
										<v-avatar
											v-if="data.text == 'All Services'"
											style="margin-left: auto"
											class="text-right text-white"
											size="35"
											:color="data.color"
											:text-color="data.textcolor"
											>{{ statusCount }}</v-avatar
										>
									</div>
								</div>
							</div>
						</div>
						<!-- All Projects -->
						<div
							:class="'card m-0 top_card card-custom gutter-b border-' + data.color"
							v-if="data.type == 'project'"
						>
							<div class="card-body p-0" @click="getFilteredData(data.value)">
								<div class="mx-2 d-flex justify-space-between py-3">
									<div class = "">
										<h5 class="mb-2 w-100 text-dark-55 font-weight-bold m-0" :class="getColor(data.color)">
											{{ data.text }}
										</h5>
										<inline-svg
											style="width: 40px; height: 40px"
											fill="#baa9a9"
											:src="$assetURL('media/custom-svg/' + data.type + '.svg')"
										/>
										
									</div>
									<div class="d-flex flex-column justify-space-between">
										<!-- v-if="data.text != 'All Quotation'" -->
										<v-avatar
											v-if="data.text != 'All Projects'"
											style="margin-left: auto"
											class="text-right text-white"
											size="35"
											:color="data.color"
											:text-color="data.textcolor"
											>{{ data.quotation_status_count }}</v-avatar
										>
										<v-avatar
											v-if="data.text == 'All Projects'"
											style="margin-left: auto"
											class="text-right text-white"
											size="35"
											:color="data.color"
											:text-color="data.textcolor"
											>{{ statusCount }}</v-avatar
										>
										<div class = "mt-1">
											<v-chip
												label
												:color="`${data.color} white--text text--darken-4 text-capitilize`"
												class="mr-2"
											>
												<ShowPrice 
													tooltip
													tooltip-value="Project Value" 
													:object="data" 
													object-key="project_amount" 
													label="Project Value"
												> </ShowPrice>
											</v-chip>
										</div>
									</div>
									
								</div>
							</div>
						</div>
						<!-- claim-expense -->
						<div
							:class="'card m-0 top_card card-custom gutter-b border-' + data.color"
							v-if="data.type == 'claim-expense'"
						>
							<div class="card-body p-0" @click="getFilteredData(data.value)">
								<div class="mx-2 d-flex justify-space-between py-3">
									<div class = "mt-4">
										<inline-svg
											style="width: 60px; height: 50px"
											fill="#baa9a9"
											:src="$assetURL('media/custom-svg/' + data.type + '.svg')"
										/>
									</div>
									<div class="d-flex flex-column justify-space-between">
										<h5 class="w-100 text-dark-55 font-weight-bold m-0" :class="getColor(data.color)">
											{{ data.text }}
										</h5>
										<!-- v-if="data.text != 'All Quotation'" -->
										<v-avatar
											v-if="data.text != 'All Expense'"
											style="margin-left: auto"
											class="text-right text-white"
											size="35"
											:color="data.color"
											:text-color="data.textcolor"
											>{{ data.quotation_status_count }}</v-avatar
										>
										<v-avatar
											v-if="data.text == 'All Expense'"
											style="margin-left: auto"
											class="text-right text-white"
											size="35"
											:color="data.color"
											:text-color="data.textcolor"
											>{{ statusCount }}</v-avatar
										>
									</div>
								</div>
							</div>
						</div>
						<!-- User -->
						<div
							:class="'card m-0 top_card card-custom gutter-b border-' + data.color"
							v-if="data.type == 'user'"
						>
							<div class="card-body p-0" @click="getFilteredData(data.value)">
								<div class="mx-2 d-flex justify-space-between py-3">
									<div class = "">
										<h5 class="mb-2 w-100 text-dark-55 font-weight-bold m-0" :class="getColor(data.color)">
											{{ data.text }}
										</h5>
										<inline-svg
											style="width: 60px; height: 50px"
											fill="#baa9a9"
											:src="$assetURL('media/custom-svg/customer.svg')"
										/>
									</div>
									<div class="d-flex flex-column justify-space-between">
										
										<!-- v-if="data.text != 'All Quotation'" -->
										<v-avatar
											v-if="data.text != 'All Users'"
											style="margin-left: auto"
											class="text-right text-white"
											size="35"
											:color="data.color"
											:text-color="data.textcolor"
											>{{ data.quotation_status_count }}</v-avatar
										>
										<v-avatar
											v-if="data.text == 'All Users'"
											style="margin-left: auto"
											class="text-right text-white"
											size="35"
											:color="data.color"
											:text-color="data.textcolor"
											>{{ statusCount }}</v-avatar
										>
									</div>
								</div>
							</div>
						</div>
					</v-flex>
				</template>
				<!-- <template v-for="(data, index) in extraList">
          <v-flex
            :key="`extra-${index}`"
            class="py-0"
            :class="{
              'mr-1': index == 0,
              'ml-1': !extraList[index + 1],
              'mx-1': index != 0 && extraList[index + 1],
            }"
          >
            <div
              :class="'card m-0 top_card card-custom gutter-b border-primary'"
            >
              <div class="card-body p-0">
                <div class="card-rounded-bottom mid_part">
                  <div
                    class="d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
                  >
                    <span
                      class="symbol symbol-circle symbol-50 symbol-light-danger mr-2"
                    >
                      <span class="symbol-label">
                        <span :class="'svg-icon svg-icon-xl svg-icon-primary'">
                          <inline-svg
                            :src="
                              $assetURL(
                                'media/custom-svg/' + moduleType + '.svg'
                              )
                            "
                          />
                        </span>
                      </span>
                    </span>
                    <div class="d-flex flex-column text-right bTitle">
                      <router-link
                        :to="
                          getDefaultRoute(moduleType, {
                            query: { status: data.status_key },
                          })
                        "
                        v-slot="{
                          href,
                          route,
                          navigate,
                          isActive,
                          isExactActive,
                        }"
                      >
                        <a :href="href" v-on:click="navigate">
                          <h5 class="text-dark-75 font-weight-bold mt-2">
                            {{ data.title }}
                          </h5>
                          <span
                            :class="'qnt_val font-weight-bolder text-primary'"
                            >{{ data.value }}</span
                          >
                        </a>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-flex>
        </template> -->
			</template>
		</v-layout>
	</div>
</template>

<script>
import { EventBus } from "@/core/event-bus/event.bus";
import ShowPrice from "@/view/components/ShowPrice";
export default {

	props: {
		dataLoading: {
			type: [Boolean],
			required: true,
			default: false,
		},
		/* moduleType: {
			type: [String],
			required: true,
			default: "customer",
		}, */
		allkey: {
			type: [String],
			required: true,
			default: "all_customers",
		},
		countkey: {
			type: [String],
			required: true,
			default: "customer_status_count",
		},
		statusList: {
			type: [Array, Object],
			required: true,
			default() {
				return new Array();
			},
		},
		statusCount: {
			type: Number,
			required: true,
			default: 0,
		},
		extraList: {
			type: [Array],
			default() {
				return new Array();
			},
		},
	},
	data() {
		return {
			dataLoadingList: [
				{
					headercolor: "primary",
					bgcolor: "bg-light-warning",
				},
				{
					headercolor: "primary",
					bgcolor: "bg-light-success",
				},
				{
					headercolor: "primary",
					bgcolor: "bg-light-info",
				},
			],
		};
	},
	methods: {
		getFilteredData(value){
			EventBus.$emit("reload:table", value);
			// this.filterRows();
		},
		getColor(color) {
			const color_name = color.split(" ");
			let class_name = "";
			if (color_name[0]) {
				class_name = `${color_name[0]}--text`;
			}
			if (color_name[1]) {
				class_name = `${class_name} text--${color_name[1]}`;
			}
			return class_name;
		},
	},
	components: {
		
		
		ShowPrice,
		
	},
};
</script>
